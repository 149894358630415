import React from 'react'
import { IconLink } from '../components/Icons'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      {/* TODO: Enable as Ready */}
      {/*
      <li>
        <IconLink name="twitter" outline />
      </li>
      <li>
        {/* <IconLink name="linkedin" outline />
      <li>
        {/* <IconLink name="github" outline />
      </li>
      */}
      <li>
        <IconLink
          name="facebook"
          suffix="/platespilates"
          outline />
      </li>
      <li>
        <IconLink
          name="instagram"
          suffix="/platespilates"
          outline />
      </li>
      <li>
        <a href="mailto:info@plates-pilates.com" className="icon solid alt fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; Plates. <span style={{fontSize: "70%"}}>All rights reserved.</span></li>
      <li style={{display: 'none'}}>
        Design: <a href="http://html5up.net">HTML5 UP</a>
      </li>
      <li>
        Images: <span style={{fontSize: "70%"}}>Plates and <a href="https://unsplash.com">Unsplash</a></span>
      </li>
    </ul>
  </footer>
)

export default Footer
