import React from 'react'


function Heading(props) {
  var title
  var className = props.class || ''
  switch (props.level) {
    case "1":
      title = <h1>{props.title}</h1>
      break;
    case "3":
      title = <h3>{props.title}</h3>
      break;
    case "4":
      title = <h4>{props.title}</h4>
      break;
    case "5":
      title = <h5>{props.title}</h5>
      break;
    case "6":
      title = <h6>{props.title}</h6>
      break;
    default:
      title = <h2>{props.title}</h2>
  }

  return <header className={className}>
           {title}
           <p>{props.text}</p>
         </header>
}

export default Heading
