import React from 'react'

/*
 * Returns a link with icon passed as a props
 * Valid prop values can be found at
 * If outline="true" is passed it is an unfilled icon
 * http://https://fontawesome.com/icons?d=gallery
 *
 */
const IconLink = props => (
  <a href={"http://www." + props.name + ".com" + props.suffix}
     className={props.outline ? "alt icon brands fa-" + props.name : "icon brands fa-" + props.name}>
    <span className="label">
      { props.name.charAt(0).toUpperCase() + props.name.slice(1) }
    </span>
  </a>
)

const IconCircleAndText = props => (
  <section className="col-4 col-6-medium col-12-xsmall">
    <span className={"icon solid alt major " + props.icon}></span>
    <h3>{props.heading}</h3>
    <p>{props.text}</p>
  </section>
)

export { IconCircleAndText, IconLink }
