import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Heading from '../components/Heading'
import Img from 'gatsby-image'

export const data = graphql`
  query {
    file(relativePath: { eq: "reformer_group.jpg" }) {
      childImageSharp {
        fluid (quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }`


const NotFoundPage = ({data}) => (
  <Layout>
    <div id="main" className="wrapper style1">
      <div className="container">
      <section id="one">
        <div className="inner">
        <Heading
          class="major"
          title="That page doesn't seem to exist"
          text=<span><p>Give us a moment to work <del>it</del> out</p><p>Go to our <Link to="/">Homepage</Link></p></span>
        />
        </div>
        <Img
          className=""
          fluid={data.file.childImageSharp.fluid}
          alt="Group exercising on reformers with outstretched arms"
        />
      </section>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
